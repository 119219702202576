@mixin cf {
    *zoom: 1;

    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}

@mixin resp_maxw($width) {
    @media all and (max-width: $width) {
        @content;
    }
}

@mixin resp_maxh($height) {
    @media all and (max-height: $height) {
        @content;
    }
}

@mixin font($weight: 400, $style: normal) {
    font-family: 'Gotham', sans-serif;
    font-weight: $weight;
    font-style: $style;
}

@mixin modal {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $half-black;
    border-radius: 25px;
    opacity: 1;
    max-width: 1089px;
    max-height: 709px;
    @include center-xy;
}

.code {
    margin: 0;
    margin-bottom: 6px;
    text-align: center;
    font-size: 20px;
    @include font(400, italic);
}

.select-container {
    display: inline-block;
    vertical-align: middle;
    box-shadow: 0 3px 6px rgba(#000, 0.5);
    padding-left: 8px;
    background: #fff;
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 100%;
        background: #EE1500;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 100%;
        background-color: #FFF;
        background-image: url(./assets/dropdown-arrow.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 12px) 50%;
        background-size: 12px;
        pointer-events: none;
    }

    select {
        display: block;
        width: 100%;
        outline: none;
        border: none;
        appearance: none;
        color: #808080;
        height: 36px;
        font-size: 15px;
        padding-left: 20px;
        cursor: pointer;
        @include font(500);
    }
}

@mixin square-button {
    border: none;
    text-transform: capitalize;
    box-shadow: 0px 3px 6px $half-black;
    padding: 18px 22px 18px 28px;
    color: $black;
    background: $white;
    transition: all 300ms ease;
    outline: none;
    cursor: pointer;
    @include font(500);

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(0% + 8px);
        height: 100%;
        background: $red;
        transition: all 300ms ease;
        z-index: 1;
    }

    span {
        position: relative;
        z-index: 2;
    }

    &:hover {
        // background-color: #CCCCCC;
        color: #fff;

        &:before {
            width: 100%;
        }
    }
}

@mixin fade-delay-in {
    transition: all 300ms ease;
    opacity: 0;
    pointer-events: none;

    &.on {
        transition: all 300ms ease 300ms;
        opacity: 1;
        pointer-events: unset;
    }
}

@mixin fade-immediate-in {
    transition: all 300ms ease;
    opacity: 0;
    pointer-events: none;

    &.on {
        transition: all 300ms ease;
        opacity: 1;
        pointer-events: unset;
    }
}

@mixin center-xy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin center-x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}


@mixin center-y {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin center-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
}

@mixin triangle-left($size, $color: $white) {
  width: 0;
  height: 0;
  border-top: $size solid transparent;
  border-bottom: $size solid transparent;

  border-right:$size solid $color;
}

@mixin triangle-up($size, $color: $white) {
    width: 0;
    height: 0;
    border-bottom: $size solid $color;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
}

@mixin scrollbars() {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f7f5f5;
    border-radius: 0px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $red;
    border-radius: 0px;
    border: 3px solid transparent;
    background-clip: content-box;
  }
}

$red: #EC1C24;
$text-red: #EE1500;
$blue: #2046E3;
$white: white;
$black: black;
$half-black: #00000080;
$dark-red: #c50007;
$light-gray: #ddd;
$dark-gray: #8c8c8c;
