@import './buttons';
@import './mixins';
@import './typography';

* {
    box-sizing: border-box;
}

body {
    @include font;
    &.chat-opened {
        .screenhider {
            width: 100%;
            height: 100%;
            background-color: black;
            position: absolute;
            z-index: 12222;
        }
    }
}

sup {
    vertical-align: top;
    position: relative;
    top: -0.4em;
    font-size: 60%;
}

// #medical-form {

//     .modal {
//         z-index: 10000;
//         position: absolute;
//     }
// }

// #twilio-customer-frame {
//     position: relative;
//     z-index: 110;
// }


