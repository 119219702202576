@import './mixins';

.modal-close {
    position: absolute;
    top: -20px;
    right: -15px;
    background: #fff;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    z-index: 10;
    transition: all 350ms ease;
    filter: drop-shadow(10px 10px 10px #000000);
    cursor: pointer;

    &:hover {
        background: $red;

        &:before,
        &:after {
            background: #fff;
        }
    }

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        background: $red;
        width: 2px;
        height: 20px;
        border-radius: 2px;
        top: 50%;
        left: 50%;
        margin-left: -1px;
        margin-top: -10px;
        transition: all 350ms ease;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

.pill-btn {
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    text-transform: uppercase;
    color: $red;
    background: #fff;
    border: 2px solid $red;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
    height: 42px;
    border-radius: 21px;
    text-align: center;
    line-height: 1.8;
    cursor: pointer;
    transition: all 350ms ease;
    padding: 0 22px;
    @include font(500);

    &:hover {
        color: #fff;
        background: $red;
    }

    &.alt {
        color: #fff;
        background: $red;

        &:hover {
            color: $red;
            background: #fff;
        }
    }

    &.arrow-left {
        span {
            margin-left: 10px;
        }
    }
    span {
        display: inline-block;
        vertical-align: middle;
        margin-right:10px;
    }

    .arrow {
        display: inline-block;
        vertical-align: middle;
        width: 17px;
        height: 22px;

        svg {
            display: block;
            width: 100%;
            height: 100%;
            fill: currentColor;
        }
    }
}

.modal-button {
    font-size: 20px;
    @include square-button;
}

.play-btn {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(assets/icon-play.svg);
    width: 29px;
    height: 21px;
    background-size: 100% 100%;
    cursor: pointer;

    &.sm {
        pointer-events: none;
    }

    &.lg {
        background-image: url(assets/icon-play-lg.svg);
        width: 69px;
        height: 50px;
    }

    &.xl {
        background-image: url(assets/icon-play-lg.svg);
        width: 91px;
        height: 65px;
    }
}
